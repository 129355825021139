<template>
  <div id="setmealList">
    <div class="table-container">
      <div class="table-filtrate">
        <ul>
          <li>
            <span class="label">输入关键字:</span>
            <el-input
              placeholder="请输入内容"
              v-model="filtrate.keyword"
              @keyup.enter.native="getList(1)"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="getList(1)"
              ></el-button>
            </el-input>
          </li>
          <li>
            <el-button class="resetBtn" size="medium" @click="reset"
              >重置</el-button
            >
          </li>
        </ul>
        <ul>
          <li>
            <el-button
              class="bindPackage"
              type="primary"
              @click="bindPackageBtn"
              v-if="
                buttonList.includes('vehicleList-detail-setmealTab-bindSetmeal')
              "
              ><i class="iconfont icon-jiahao"></i> 绑定套餐</el-button
            >
          </li>
        </ul>
      </div>
      <el-table
        :data="table.tableData"
        style="width: 100%"
        v-loading="table.loading"
      >
        <el-table-column
          prop="parkingLotName"
          label="停车场名称"
          align="center"
        >
        </el-table-column>
        <el-table-column
          prop="parkingLotAddress"
          label="停车场位置"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="leaseType" label="套餐类型" align="center">
        </el-table-column>
        <el-table-column
          prop="leaseTimesFormat"
          label="时间范围"
          align="center"
          width="260px"
        >
          <template slot-scope="scope">
            <p
              v-for="(time, timeIndex) of scope.row.leaseTimesFormat"
              :key="timeIndex"
              :style="{ color: time.notExpired ? 'red' : 'white' }"
            >
              {{ time.format }}
            </p>
          </template>
        </el-table-column>
      </el-table>
      <div class="pagination">
        <el-pagination
          layout="sizes, total, prev, pager, next, jumper"
          background
          :total="table.total"
          :page-size.sync="table.pageSize"
          :current-page.sync="table.page"
          @current-change="getList"
          @size-change="getList(1)"
        >
        </el-pagination>
      </div>
    </div>
    <bind-package-dialog ref="bindPackageDialog" :getList="getList" />
  </div>
</template>
<script>
export default {
  props: ["info"],
  components: {
    bindPackageDialog: () => import("../bindPackageDialog.vue"),
  },
  data() {
    return {
      filtrate: {
        keyword: "",
      },
      table: {
        tableData: [],
        total: 0,
        page: 1,
        pageSize: 10,
        loading: false,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    del(leaseId) {
      this.$confirm("此操作将永久删除该车辆, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res = await this.$http.delete(`/price/lease/delete/${leaseId}`);
          if (res.code === 0) {
            this.$message.success("删除成功");
            this.getList();
          } else {
            this.$message.error(res.msg);
          }
        })
        .catch(() => {});
    },
    bindPackageBtn() {
      this.$refs.bindPackageDialog.addForm.plateNum = this.info.plateNum;
      this.$refs.bindPackageDialog.show = true;
    },
    reset() {
      this.filtrate = {
        keyword: "",
      };
      this.getList(1);
    },
    async getList(page = this.table.page) {
      this.table.page = page;
      try {
        this.table.loading = true;
        let res = await this.$http.post("/price/lease/list/byVehicle", {
          keyword: this.filtrate.keyword,
          pageNum: page,
          pageSize: this.table.pageSize,
          vehicleId: this.$route.params.id,
        });
        if (res.code === 0) {
          for (let item of res.data.list) {
            item.leaseTimesFormat = item.leaseTimes.map((item) => {
              return {
                format: `${this.$moment(item.startTime).format(
                  "yyyy-MM-DD HH:mm:ss"
                )} ~ ${
                  item.endTime
                    ? this.$moment(item.endTime).format("yyyy-MM-DD HH:mm:ss")
                    : ""
                }`,
                notExpired: this.$moment(item.endTime).isBefore(this.$moment()),
              };
            });
          }

          this.table.tableData = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg);
        }
      } catch (err) {
        this.$message.error(err);
      } finally {
        this.table.loading = false;
      }
    },
  },
};
</script>
<style lang="scss">
#setmealList {
  .table-container {
    .table-filtrate {
      ul {
        li {
          .bindPackage {
            background-color: #ff9747;
            border-color: #ff9747;
            i {
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}
</style>
